/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: DevonCarlson (https://sketchfab.com/devoncarlson)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/tall-potted-house-plant-9d67ee5efd7e414ebf81a3dcc6bf7ada
title: Tall Potted House Plant
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/tallplant.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group scale={0.01}>
            <mesh geometry={nodes.potTall_aglaonema_phong4_0.geometry} material={materials.phong4} />
            <mesh geometry={nodes.potTall_aglaonema_phong1_0.geometry} material={materials.phong1} />
            <mesh geometry={nodes.potTall_aglaonema_phong2_0.geometry} material={materials.phong2} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/tallplant.glb')
