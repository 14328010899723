/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: soloconART (https://sketchfab.com/soloconart)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/cork-coaster-8a3dd816ff1c42cbb5090f5f26adce69
title: Cork Coaster
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/coaster.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <mesh geometry={nodes.Cork_Coaster_Cork_Coaster_0.geometry} material={materials.Cork_Coaster} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/coaster.glb')
