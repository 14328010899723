/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/keyboard.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube.geometry} material={materials['Material.005']} position={[0.02, 1.31, 0]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube001.geometry} material={materials.Material} position={[0.02, 1.31, -2]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube002.geometry} material={materials.Material} position={[0.02, 1.31, -4]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube003.geometry} material={materials.Material} position={[0.02, 1.31, -6]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube004.geometry} material={materials.Material} position={[0.02, 1.31, -8]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube005.geometry} material={materials.Material} position={[0.02, 1.31, -10]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube006.geometry} material={materials.Material} position={[0.02, 1.31, -12]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube007.geometry} material={materials.Material} position={[0.02, 1.31, -14]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube008.geometry} material={materials.Material} position={[0.02, 1.31, -16]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube009.geometry} material={materials.Material} position={[0.02, 1.31, -18]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube010.geometry} material={materials.Material} position={[0.02, 1.31, -20]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube011.geometry} material={materials.Material} position={[0.02, 1.31, -22]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube012.geometry} material={materials.Material} position={[2.01, 1.13, -25]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube013.geometry} material={materials.Material} position={[0.02, 1.31, -27]} rotation={[0, 0, -0.09]} scale={[1, 1, 2]} />
      <mesh geometry={nodes.Cube014.geometry} material={materials.Material} position={[0.02, 1.31, -24]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube015.geometry} material={materials.Material} position={[2.01, 1.13, -3]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube016.geometry} material={materials.Material} position={[2.01, 1.13, -5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube017.geometry} material={materials.Material} position={[2.01, 1.13, -7]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube018.geometry} material={materials.Material} position={[2.01, 1.13, -9]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube019.geometry} material={materials.Material} position={[2.01, 1.13, -11]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube020.geometry} material={materials.Material} position={[2.01, 1.13, -13]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube021.geometry} material={materials.Material} position={[2.01, 1.13, -15]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube022.geometry} material={materials.Material} position={[2.01, 1.13, -17]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube023.geometry} material={materials.Material} position={[2.01, 1.13, -19]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube024.geometry} material={materials.Material} position={[2.01, 1.13, -21]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube025.geometry} material={materials.Material} position={[2.01, 1.13, -23]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube026.geometry} material={materials.Material} position={[2.01, 1.13, -27.5]} rotation={[0, 0, -0.09]} scale={[1, 1, 1.5]} />
      <mesh geometry={nodes.Cube027.geometry} material={materials.Material} position={[2.01, 1.13, -0.5]} rotation={[0, 0, -0.09]} scale={[1, 1, 1.5]} />
      <mesh geometry={nodes.Cube028.geometry} material={materials.Material} position={[4, 0.96, -0.78]} rotation={[0, 0, -0.09]} scale={[1, 1, 1.75]} />
      <mesh geometry={nodes.Cube029.geometry} material={materials.Material} position={[4, 0.96, -3.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube030.geometry} material={materials.Material} position={[4, 0.96, -5.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube031.geometry} material={materials.Material} position={[4, 0.96, -7.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube032.geometry} material={materials.Material} position={[4, 0.96, -9.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube033.geometry} material={materials.Material} position={[4, 0.96, -11.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube034.geometry} material={materials.Material} position={[4, 0.96, -13.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube035.geometry} material={materials.Material} position={[4, 0.96, -15.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube036.geometry} material={materials.Material} position={[4, 0.96, -17.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube037.geometry} material={materials.Material} position={[4, 0.96, -19.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube038.geometry} material={materials.Material} position={[4, 0.96, -21.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube039.geometry} material={materials.Material} position={[4, 0.96, -23.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube041.geometry} material={materials['Material.005']} position={[4, 0.96, -26.75]} rotation={[0, 0, -0.09]} scale={[1, 1, 2.25]} />
      <mesh geometry={nodes.Cube042.geometry} material={materials.Material} position={[5.99, 0.79, -26.25]} rotation={[0, 0, -0.09]} scale={[1, 1, 2.75]} />
      <mesh geometry={nodes.Cube043.geometry} material={materials.Material} position={[5.99, 0.79, -20.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube044.geometry} material={materials.Material} position={[5.99, 0.79, -4.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube046.geometry} material={materials.Material} position={[5.99, 0.79, -8.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube047.geometry} material={materials.Material} position={[5.99, 0.79, -10.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube048.geometry} material={materials.Material} position={[5.99, 0.79, -12.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube049.geometry} material={materials.Material} position={[5.99, 0.79, -14.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube050.geometry} material={materials.Material} position={[5.99, 0.79, -16.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube051.geometry} material={materials.Material} position={[5.99, 0.79, -18.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube052.geometry} material={materials.Material} position={[5.99, 0.79, -22.5]} rotation={[0, 0, -0.09]} />
      <mesh geometry={nodes.Cube054.geometry} material={materials.Material} position={[7.99, 0.61, -27.75]} rotation={[0, 0, -0.09]} scale={[1, 1, 1.25]} />
      <mesh geometry={nodes.Cube053.geometry} material={materials.Material} position={[7.99, 0.61, -25.25]} rotation={[0, 0, -0.09]} scale={[1, 1, 1.25]} />
      <mesh geometry={nodes.Cube055.geometry} material={materials.Material} position={[7.99, 0.61, -22.75]} rotation={[0, 0, -0.09]} scale={[1, 1, 1.25]} />
      <mesh geometry={nodes.Cube056.geometry} material={materials.Material} position={[7.99, 0.61, -0.25]} rotation={[0, 0, -0.09]} scale={[1, 1, 1.25]} />
      <mesh geometry={nodes.Cube057.geometry} material={materials.Material} position={[5.99, 0.79, -1.25]} rotation={[0, 0, -0.09]} scale={[1, 1, 2.25]} />
      <mesh geometry={nodes.Cube058.geometry} material={materials.Material} position={[7.99, 0.61, -2.75]} rotation={[0, 0, -0.09]} scale={[1, 1, 1.25]} />
      <group position={[7.99, 0.61, -5.25]} rotation={[0, 0, -0.09]} scale={[1, 1, 1.25]}>
        <mesh geometry={nodes.Cube063.geometry} material={materials.Material} />
        <mesh geometry={nodes.Cube063_1.geometry} material={materials['Material.005']} />
      </group>
      <mesh geometry={nodes.Cube061.geometry} material={materials.Material} position={[7.99, 0.61, -20.25]} rotation={[0, 0, -0.09]} scale={[1, 1, 1.25]} />
      <mesh geometry={nodes.Cube062.geometry} material={materials['Material.003']} position={[3.8, -1.3, -14]} />
      <mesh geometry={nodes.Cube040.geometry} material={nodes.Cube040.material} position={[4, -1.79, -14]} scale={0.91} />
      <mesh geometry={nodes.Cube045.geometry} material={materials.Material} position={[5.99, 0.79, -6.5]} rotation={[0, 0, -0.09]} />
    </group>
  )
}

useGLTF.preload('/keyboard.glb')
